import { useTranslation } from 'react-i18next';

import { DescriptionSection } from '../sections/descriptionSection/DescriptionSection';
import { Checkbox } from '../../../ui/checkbox/Checkbox';
import { FileSelector } from '../../../ui/fileSelector/FileSelector';

export const Step2 = ({ title, description, agreement, file, setTitle, setDescription, setAgreement, setFile, isDisabled }) => {
    const { t } = useTranslation();

    return (
        <div className="w-full flex-col gap-10 flex">
            <div>
                <div className="mb-3 text-neutral-900 text-base font-medium">{t('TITLE')}</div>

                <input
                    type="text"
                    className="h-10 w-full text-sm rounded border-neutral-200"
                    placeholder={t('ENTER_TITLE')}
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    disabled={isDisabled}
                />
            </div>

            <DescriptionSection description={description} setDescription={setDescription} disabled={isDisabled} />

            <div className="text-sm -mt-7">
                <Checkbox
                    title={t('NEWS_AND_POLLS_REQUEST_FOR_EMPLOYEE_CONSENT')}
                    checked={agreement}
                    onClick={() => setAgreement(!agreement)}
                    disabled={isDisabled}
                />
            </div>

            <div>
                <div className="mb-3 text-neutral-900 text-base font-medium">{t('UPLOADING_FILES')}</div>

                <FileSelector
                    maxFiles={1}
                    placeholder={t('UPLOAD')}
                    callback={files => {
                        setFile(files[0]);
                    }}
                    initialFiles={file ? [file] : []}
                    accept={{ 'image/jpg': ['.png', '.jpeg', '.jpg', '.webp', '.heic', '.heif'] }}
                    disabled={isDisabled}
                />
            </div>
        </div>
    );
};
